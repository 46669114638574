import React from 'react'
import {graphql} from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import HomeProductLine from "../components/product/product-line";
import ProductMain from "../components/product/product-main";
import QualityAssurance from "../components/assurance/quality-assurance";
import CooperativePartner from "../components/partner/partner";
import Form from "../components/form";
import Banner from "../components/banner/banner";
import {convertFactory} from "../utils/convert";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SEO from "../components/seo";
import {Factory} from "../components/factory";

class RootIndex extends React.Component {
  render() {
    const bannerNodes = get(this, 'props.data.allContentfulBanner.edges')
    const productLineNodes = get(this, 'props.data.allContentfulProductLine.edges')
    const allFacory = get(this, 'props.data.allContentfulFacory.nodes')
    const partnerNodes = get(this, 'props.data.allContentfulPartner.edges')
    const allAssurance = get(this, 'props.data.allContentfulAssurance.nodes')
    const allKeywords = get(this, 'props.data.allContentfulPageKeywords.nodes')
    const homeFullCustomizable = get(this, 'props.data.homeFullCustomizable')
    const homeCommitment = get(this, 'props.data.homeCommitment')
    const banners = bannerNodes.map(x => x.node)
    const lines = productLineNodes.map(x => x.node)
    const partners = partnerNodes.map(x => x.node)
    const allFactory = get(this, 'props.data.allContentfulFacory.nodes')

    return (
      <Layout location={this.props.location}>
        <SEO title={'HomeBest  manufacturer of extension sockets, wall switches and lamps in China'} keywords={allKeywords[0]?.keywords}/>
        <div style={{background: '#fff'}}>
          <div>
            <Banner banners={banners}/>
          </div>
          <div className="mt-5 mb-5">
            <HomeProductLine lines={lines}/>
          </div>
          <div className="mb-5">
            <ProductMain lines={lines}/>
          </div>
          <div className="mb-5">
            <Factory factories={allFactory}/>
          </div>
          <div className="container mb-5">
            <div>
              <h2 className="text-center p-4">Fully Customizable</h2>
              <div className="card border-0">
                <div className="row g-0">
                  <div className="col-md-5">
                    <div className="card-body">
                      <h3 className="card-title">Module customization</h3>
                      <p className="card-text pt-2">
                        In order to meet the diversified market needs, we provide customized products and services for
                        customers in Singapore, Japan, Saudi Arabia and other countries. We have the advantages of
                        a mature system, low cost and rich experience. As a large-scale integrated manufacturing and
                        export company, we have passed China ISO, Kenya, KEBS, Dubai, EQM, Saudi SQM, and other
                        countries’ customers’ system audits in the past 20 years.
                      </p>
                      <div>
                        <div className="learn-more">
                          <AniLink to={`/contact-us`} duration={0.3} hex="#9dc428" paintDrip>
                            Learn More
                          </AniLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <img src={homeFullCustomizable.file.url} alt={homeFullCustomizable.title}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 w-75 container">
            <QualityAssurance assurances={allAssurance}/>
          </div>
          <div className="container">
            <div className="card mb-5 border-0">
              <div className="row g-0">
                <div className="col-md-7">
                  <img src={homeCommitment.file.url} alt={homeCommitment.title}/>
                </div>
                <div className="col-md-5">
                  <div className="card-body">
                    <h3 className="card-title">Our Commitment to You</h3>
                    <p className="card-text pt-2"><small className="text-muted">We have proudly worked with these
                      innovators to speed up their product launch progress</small></p>
                    <ul className="pt-2">
                      <li>
                        Unlimited Modifications Before Mass Production
                      </li>
                      <li>
                        Professional Technical Support
                      </li>
                      <li>
                        Two-Year Warranty
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="contact-us" className="mb-5">
            <Form lang={'en'}/>
          </div>
          <div>
            <CooperativePartner partners={partners}/>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulProductLine(sort: { fields: [name], order: DESC }, filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          name
          identity
          imageLink {
            file{
              url
            }
          }
          products {
            id
            name
            price
            link
            minOrder
            imageLink {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
    allContentfulBanner(sort: { fields: [name], order: DESC }, filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          name
          imageLink {
            title
            file {
              url
            }
          }
          type
        }
      }
    }
    allContentfulAssurance(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        id
        name
        imageLink {
          title
          fluid(maxWidth: 1440, quality: 100) {
            src
          }
        }
      }
    }
    allContentfulPartner(sort: { fields: [name], order: DESC }, filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          name
          logo {
            fluid {
              src
            }
          }
        }
      }
    }
    allContentfulPageKeywords(filter: {page: {eq: "home"}, node_locale: {eq: "en-US"}}) {
        nodes {
            keywords
            node_locale
            page
        }
    }
    allContentfulFacory(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        id
        imageLink {
          id
          title
          file {
            url
          }
        }
        introduction {
          introduction
        }
        name
      }
    }
    homeCommitment: contentfulAsset(title: {eq: "home_commitment"}) {
      title
      file {
        url
      }
    }
    homeFullCustomizable: contentfulAsset(title: {eq: "home_full_customizable"}) {
      title
      file {
        url
      }
    }
  }
`
